.page-section {
    padding-top: 3em;
    padding-bottom: 3em;
    background-color: @color-white;
}
.page-section-off {
    background-color: @color-offWhite;
}
.page-section-short {
    padding-top: 2em;
    padding-bottom: 2em;
}
.page-section-inv {
    background-color: @bg-dark;
    color: @text-light-muted;
    .page-section-heading, .page-heading-subsection {
        color: @color-white;
    }
}
.page-section-brand {
    background-color: @color-brand;
    color: @color-white;
    .page-section-heading, .page-heading-subsection {
        color: @color-white;
    }
}
.page-section-heading {
    color: @text-dark;
    font-weight: @font-weight-light;
    font-size: 2.5em;
    margin-top: 0;
    margin-bottom: 0.5em;
}
.page-section-subheading {
    color: @text-dark;
    font-weight: @font-weight-semibold;
    font-size: 1.125em;
    margin-top: 1em;
    margin-bottom: 0.5em;
}
.divided-top {
    border-top: 1px solid @color-grayLightest;
}
.divided-bottom {
    border-bottom: 1px solid @color-grayLightest;
}
