.list-signup {
    // padding-top: 1em;
    // padding-bottom: 1em;
}

.list-signup-heading {
    margin-top: 0;
    margin-bottom: 1.1em;
    margin-left: 0;
    margin-right: 0;
    font-size: 1.1em;
}

.list-signup-form {
    // padding-top: 1.2em;
    // padding-bottom: 1.2em;
    max-width: 18em;
    margin-left: auto;
    margin-right: auto;

    @media(min-width: @screen-sm-min) {
        max-width: none;
    }
}

.list-signup-subtext {
    margin: 0;
}

.list-signup-errors {
    color: rgb(255, 255, 255);
    padding-top: 1em;
    margin: 0;
    font-size: 1em;
}
