.navbar {
    font-size: 1rem;
}

.navbar-content {
    display: flex;
    justify-content: space-between;
}

.navbar-brand {
    color: @text-light-soft;
    background: @color-brand;
    .navbar-link {
        color: @text-light-soft;
        &:hover, &:focus {
            color: @text-light;
            text-decoration: none;
        }
    }
    .navbar-logo > .logo {
        color: @color-white;
    }
}

.navbar-logo {
    padding-top: 0.5em;
    padding-bottom: 0.5em;
    font-size: 2em;
    font-weight: @font-weight-semibold;
}

.navbar-actions {
    display: flex;
    justify-content: flex-end;
}

.navbar-action {
    display: flex;
    align-items: center;
    margin-left: 3em;

    &:first-child {
        margin-left: 0;
    }

    &.dropdown {
        align-items: stretch;
        &.open {
            background-color: rgba(0, 0, 0, 0.1);
        }
        > a {
            display: flex;
            align-items: center;
            padding-left: 1em;
            padding-right: 1em;
            &:hover {
                text-decoration: none;
                color: @color-white;
            }
        }
    }

    > .dropdown-menu {
        left: initial;
        right: 0;
        margin-top: 0;
        border-top-left-radius: 0;
        border-top-right-radius: 0;
    }
}
