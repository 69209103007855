.btn {
    transition: color 0.15s, background-color 0.15s, border-color 0.15s;
    border-width: 0.125em;
    border-radius: 0.375em;
    line-height: 1.5;
    padding: (0.5em - 0.0625em) 1.5em;
    font-size: 1em;
}

.btn-sm {
    font-size: 0.85em;
}

.button-outline-variant(@color; @background; @border) {
    .button-variant(@color; @background; @border);
    &:hover, &:focus, &:active, &:focus:active  {
        background: @color;
        border-color: @color;
        color: @color-white;
    }
    &[disabled]:hover {
        color: @color;
    }
}


.btn-brand {
    .button-variant(@color-white; @color-brand; @color-brand);
}

.btn-brand-inv {
    .button-variant(@color-brand; @color-white; @color-white);
}

.btn-default {
    .button-outline-variant(@color-brand; transparent; @color-brand);
}

.btn-success {
    .button-outline-variant(@color-green; transparent; @color-green);
}

.btn-default-inv {
    .button-variant(@color-white; transparent; @color-white);
    &[disabled]:hover {
        color: @color-white;
    }
    &:hover, &:focus, &:active, &:focus:active {
        background: @color-white;
        border-color: @color-white;
        color: @color-brand;
        box-shadow: none;
    }
}

.btn-link-inv {
    color: @color-white;
    &:hover,
    &:focus {
        color: @color-white;
    }
    &[disabled],
    fieldset[disabled] & {
        &:hover,
        &:focus {
            color: @color-grayLightest;
            text-decoration: none;
        }
    }
}

.btn-muted {
    .button-variant(@color-grayLight; transparent; @color-grayLight);
    &:hover {
        background: @color-white;
        border-color: @color-gray;
        color: @color-gray;
    }
    &[disabled]:hover {
        color: @color-grayLight;
        border-color: @color-grayLight;
    }
}

.btn-muted-danger {
    &:hover {
        background: @brand-danger;
        border-color: @brand-danger;
        color: @color-white;
    }
    &[disabled]:hover {
        color: @color-grayLight;
        border-color: @color-grayLight;
    }
}

.btn-padded {
    padding: 1em 3em;
}

.btn-wide {
    padding-left: 3em;
    padding-right: 3em;
}

// Bootstrap override
.btn-block + .btn-block {
    margin-top: 0;
}
