.marketing-section {
    margin-bottom: 3em;
    @media (min-width: @screen-sm-min) {
        margin-bottom: 0;
    }
}

.marketing-section-heading {
    margin-top: 0;
    margin-bottom: 2em;
}

.marketing-step {
    padding-left: 1em;
    padding-right: 1em;
}

.marketing-step-img {
    text-align: center;
    > img {
        max-width: 100%;
    }
}

.marketing-step-content {
    text-align: left;
    @media (min-width: @screen-sm-min) {
        text-align: center;
    }
}
