.action-bar {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.action-bar-padded {
    padding-top: 1em;
    padding-bottom: 1em;
}

.action-bar-divided {
    .divided-bottom;
    &:last-child {
        border-bottom: none;
    }
}

.action-bar-item {
    flex: 1;
}

.action-bar-actions {
    flex: none;
    text-align: right;
}

.action-bar-action {
    display: inline-block;
    margin-left: 1em;
}

.action-bar-text {
    flex: 1;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow-x: hidden;
}
