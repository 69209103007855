// Bootstrap overrides

@modal-md: 28em;

.modal-body {
    padding: 2em;
}

.modal-footer-centered {
    text-align: center;
}
