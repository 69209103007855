.form-control {
    border-color: @color-grayLight;
}

.form-control-constrained {
    max-width: 30em;
}

.input-lg {
    font-size: 1em;
    height: auto;
    line-height: 1.5;
    padding: 0.5em 1.5em;
}

.form-section {
    padding-bottom: 2em;
    margin-bottom: 2em;
    border-bottom: 1px solid @color-grayLightest;
    &:last-child {
        border-bottom: none;
    }
}
