.blank-slate {
    text-align: center;
    padding: 4em;
}

.blank-slate-heading {
    color: @color-grayDark;
    font-size: @font-size-h3;
    margin-bottom: 1em;
}

.blank-slate-body {
    font-size: @font-size-h4;
}
