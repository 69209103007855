.flex-container {
    display: flex;
    flex-wrap: wrap;
}

.flex-container-sm {
    @media(min-width: @screen-sm-min) {
        display: flex;
    }
}

.flex-container-centered {
    align-items: center;
    justify-content: center;
}

.flex-container-spaced {
    justify-content: space-around;
}

.flex-container-justified {
    justify-content: space-between;
}

.flex-item {
    flex-basis: 0;
    flex-grow: 1;
    flex-shrink: 1;
}
