.pricing-cards {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    @media(min-width: (14em * 4 + 4em)) {
        justify-content: space-between;
    }
}
.pricing-card {
    .card;
    display: flex;
    flex: 0 1 14em;
    flex-direction: column;
    justify-content: space-between;
    text-align: left;
    margin-left: 3em;
    margin-right: 3em;

    @media(min-width: (14em * 4 + 4em)) {
        margin-left: 0;
        margin-right: 0;
    }
}

.pricing-card-title {
    font-size: 1.5em;
    font-weight: @font-weight-semibold;
    margin-top: 0;
    margin-bottom: 0.25em;
}

.pricing-card-price {
    display: block;
    font-size: 1.25em;
    font-weight: @font-weight-light;
    line-height: 1.25;
    margin-bottom: 0.75em;
}

.pricing-card-features {
    .list-unstyled;
    margin-bottom: 2em;
    font-size: 0.9em;
    > li {
        margin-bottom: 0.5em;
    }
}

.pricing-card-subtext {
}
