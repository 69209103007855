@import "bootstrap/bootstrap.less";
@import "colors.less";
@import "typography.less";
@import "utilities.less";
@import "buttons.less";
@import "tables.less";
@import "forms.less";
@import "modals.less";
@import "vue.less";
@import "flex.less";
@import "hero.less";
@import "marketing.less";
@import "page-section.less";
@import "page-heading.less";
@import "card.less";
@import "pricing-card.less";
@import "plans-table.less";
@import "navbar.less";
@import "action-bar.less";
@import "blank-slate.less";
@import "list-signup.less";

@font-family-sans-serif:  "proxima-nova", Helvetica, Arial, sans-serif;
@font-family-logo:  "proxima-nova-soft", Helvetica, Arial, sans-serif;
@font-size-base: 16px;

@text-color: @text-dark;
@brand-primary: @color-brand;

html {
    font-size: @font-size-base * 0.875;

    @media (min-width: @screen-sm-min) {
        font-size: @font-size-base;
    }
}

body {
    font-size: 1rem;
}

.body {
    display: flex;
    min-height: 100vh;
    flex-direction: column;
}

.body-content {
    flex: 1;
    background-color: @color-offWhite;
}

.page-content {
    padding-bottom: 2em;
}

.container, .container-fluid {
    max-width: 1000px;
}

.container-narrow {
    max-width: 42em;
}

.block-justified {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.logo {
    font-family: @font-family-logo;
}

.loader {
    text-align: center;
    padding-top: 1em;
    padding-bottom: 1em;
}
.loader-lg {
    font-size: 3em;
    padding-top: 3em;
    padding-bottom: 3em;
}

.avatar {
    max-width: 2em;
    margin-right: 0.5em;
    border-radius: 0.375em;
}
