@font-weight-light: 300;
@font-weight-regular: 400;
@font-weight-semibold: 600;
@font-weight-bold: 700;

@text-light: rgb(255, 255, 255);
@text-light-soft: fade(@text-light, 85%);
@text-light-muted: fade(@text-light, 60%);
@text-dark: hsl(hue(@color-brand), 20%, 20%);
@text-dark-soft: fade(@text-dark, 60%);
@text-dark-muted: fade(@text-dark, 35%);
@text-lg: 1.25em;
@text-sm: 0.85em;

h1, h2, h3, h4, h5, h6 {
    margin-top: 0;
    margin-bottom: 0.5em;
}
p {
    margin-bottom: 1.4em;
    &:last-child {
        margin-bottom: 0;
    }
}
.p-constrained {
    margin-left: auto;
    margin-right: auto;
    max-width: 30em;
}

.wt-light {
    font-weight: @font-weight-light;
}
.wt-normal {
    font-weight: @font-weight-regular;
}
.wt-medium {
    font-weight: @font-weight-semibold;
}
.wt-bold {
    font-weight: @font-weight-bold;
}

.text-light {
    color: @text-light;
}
.text-light-soft {
    color: @text-light-soft;
}
.text-light-muted {
    color: @text-light-muted;
}
.text-dark {
    color: @text-dark;
}
.text-dark-soft {
    color: @text-dark-soft;
}
.text-dark-muted {
    color: @text-dark-muted;
}

.text-lg {
    font-size: @text-lg;
}
.text-sm {
    font-size: @text-sm;
}

.link-subtle {
    color: inherit;
    opacity: 0.7;
    &:hover, &:focus {
        color: inherit;
        opacity: 0.9;
    }
}
.link-inv {
    color: @color-white;
    &:hover, &:focus {
        color: @color-white;
    }
}
.link-plain {
    color: inherit;
    &:hover, &:focus {
        color: inherit;
        text-decoration: none;
    }
}
