.page-heading {
    background: @color-white;
    margin-bottom: 2em;
    border-bottom: 1px solid @color-grayLightest;
}
.page-heading-title {
    color: @text-dark;
    padding-top: 1em;
    padding-bottom: 1em;
    font-size: 1.1em;
    margin-top: 0;
    margin-bottom: 0;
}
