.u-drop-shadow {
    box-shadow: 0 2px 3px rgba(0, 0, 0, 0.05);
}

.block-spaced {
    margin-bottom: 2em;
}

.u-inline-block {
    display: inline-block;
}

.mar-b-sm { margin-bottom: 0.5em !important; }
.mar-b { margin-bottom: 1em !important; }
.mar-b-lg { margin-bottom: 2em !important; }
.pad-t-lg { padding-top: 2em !important; }
