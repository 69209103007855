.hero {
    display: flex;
    flex-direction: column;
    text-align: center;

    @media (min-width: @screen-sm-min) {
        font-size: 1.25em;
    }
}

.hero-brand {
    background: @color-brand;
    // background-url: url('/img/header-bg.jpg');
    // background-position: center;
    // background-size: cover;
    color: @color-white;
}

.hero-main {
    flex: 1;
    margin-top: 2em;
    margin-bottom: 1em;
}

.hero-content {
    margin-bottom: 2em;
}

.hero-heading {
    font-size: 2.75em;
    font-weight: @font-weight-light;
    margin-top: 0;
    margin-bottom: 0.5em;
}

.hero-subheading {
    font-size: 1.5em;
    font-weight: @font-weight-light;
    max-width: 24em;
    margin: 0 auto;
    color: rgba(255, 255, 255, 0.9);

    > strong {
        color: @color-white;
        font-weight: @font-weight-semibold;
    }
}

.hero-form-control {
    display: inline-block;
    font-size: 1em;
    height: auto;
    line-height: @line-height-base;
    padding-top: @padding-large-vertical;
    padding-bottom: @padding-large-vertical;
    padding-left: @padding-large-horizontal;
    padding-right: @padding-large-horizontal;
    margin-bottom: 1.2em;

    @media(min-width: @screen-sm-min) {
        max-width: 18em;
        margin-bottom: 0;
        margin-right: 1em;
    }
}

.hero-image {
    max-width: 100%;
    @media(min-width: @screen-sm-min) {
        max-width: 90%;
    }
    @media(min-width: @screen-md-min) {
        max-width: 80%;
    }
    @media(min-width: @screen-lg-min) {
        max-width: 70%;
    }
}
