.plans-table {

}
.plans-table-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1em;
    &:last-child {
        margin-bottom: 0;
    }
}
.plans-table-action {
    width: 8em;
}
@media (min-width: @screen-md-min) {
    .plans-table-plan-title {
        display: inline-block;
        min-width: 8em;
    }
    .plans-table-plan-info {
        display: inline-block;
        min-width: 12em;
    }
    .plans-table-plan-price {
        display: inline-block;
    }
}
